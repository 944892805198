<!-- eslint-disable vue/no-v-html -->
<template>
  <div ref="root" class="duo-text">
    <div ref="wrapper" class="duo-text-wrapper wrapper">
      <slot />
      <div class="duo-text__content">
        <HtmlText class="duo-text__content__text" :content="content.htmltext" />

        <Action
          v-if="content.link"
          :content="content.link"
          class="duo-text__content__link"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Link } from '@/types'

export interface DuoText {
  htmltext: string
  link?: Link
}
</script>

<script setup lang="ts">
import gsap from 'gsap'
import { onMounted, ref } from 'vue'

import { useAppear } from '@/utils/aware'

import type { PropType } from 'vue'

const root = ref(null)
const wrapper = ref()

defineProps({
  content: {
    type: Object as PropType<DuoText>,
    required: true,
  },
})

onMounted(() => {
  if (root.value) {
    const tl = gsap.timeline({})
    tl.set(wrapper.value, {
      opacity: 0,
    })
  }
})

useAppear(
  root,
  ({ isInViewport }) => {
    if (isInViewport) {
      const tl = gsap.timeline()

      tl.to(wrapper.value, {
        duration: 1.5,
        opacity: 1,
        ease: 'Power1.easeOut',
      })
    }
  },
  { rootMargin: '-25% 0px', once: true }
)
</script>

<style lang="scss" scoped>
.duo-text {
  overflow: hidden;
  margin: $spacing * 2 0;
}

.duo-text-wrapper {
  @include mq($until: m) {
    padding-inline: col(1);
  }

  @include mq(m) {
    display: flex;
    align-items: flex-start;
  }
}

.duo-text__content {
  @include mq(m) {
    width: col(8);
    margin-left: auto;
  }

  @include mq(xl) {
    padding-right: col(1);
  }
}

.duo-text__content__title {
  margin-top: 0;
  margin-bottom: 1.6rem;
  color: $c-green-abr;
}

.duo-text__content__link {
  margin-block: 3.6rem;
}

.duo-text__content__ctas {
  margin-top: 3.6rem;
}

.duo-text__content__cta + .duo-text__content__cta {
  margin-top: 0.8rem;
}
</style>
